<template>
    <v-row no-gutters>
        <v-col class="col-12">
            <h2 v-if="this.anuncios.length == 0">Nenhum anuncio encontado.</h2>
        </v-col>
        <v-col class="col-12" v-if="this.anuncios.length > 0">
                <h2>Meus anuncios</h2>
        </v-col>
        <div class="col-12 mt-5"
            v-for="(anuncio, index) in this.anuncios" :key="index">
            <v-card>
                <v-card-text>
                    <v-col
                        v-if="anuncio.Fotos && anuncio.Fotos.length > 0">
                        <v-carousel cycle hide-delimiter-background :height="200">
                            <div
                            v-for="(arquivo, index) in anuncio.Fotos" :key="index">
                                <v-carousel-item
                                        :src="arquivo.Url"
                                        aspect-ratio="16/9"
                                        >
                                </v-carousel-item>
                            </div>
                        </v-carousel>
                    </v-col>
                    <v-row>
                        <v-col cols="12">
                            <h2>{{ anuncio.NomeEmpreendimento }}</h2>
                            <h4>Identificador: <span style="font-weight: 400">{{ anuncio.Unidade }}</span></h4>
                            <h4>Descrição: <span style="font-weight: 400">{{ anuncio.Descricao }}</span></h4>
                            <h4>
                                {{ 
                                    anuncio.Valor.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                }}
                            </h4>
                            <h4>Telefone: <span style="font-weight: 400">{{ anuncio.Telefone }}</span></h4>
                            <v-col
                                cols="12" style="display: flex; justify-content: end;">
                                <v-btn
                                    small
                                    color="red darken-1"
                                    text
                                    variant="tonal"
                                    @click="excluirAnuncio(anuncio)"
                                    >
                                        Excluir
                                </v-btn>
                                <v-dialog
                                    persistent
                                    :retain-focus="false"
                                    max-width="350px"
                                    v-model="dialogRemover"
                                >
                                    <v-card>
                                    <v-card-title>
                                        <h4>Tem certeza que quer excluir?</h4>
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        color="red darken-1"
                                        text
                                        outlined
                                        @click="dialogRemover = false"
                                        >
                                            Não
                                        </v-btn>
                                        <v-btn
                                        color="light-green accent-4"
                                        text
                                        outlined
                                        @click=""
                                        >
                                            Sim
                                        </v-btn>
                                    </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-row>
  </template>

<script>
import Usuario from '@/store/Usuario'
import Cookie from 'js-cookie'


export default {
    props: {
        anuncios: {type: Array, default: []}
    },
    mounted: function(){

    },
    data(){
      return{
        dialogRemover: false,
        anuncios: [],
      }
    },
    methods: {
        excluirAnuncio(anuncio){
            try {
                this.$loading(true)
                Usuario.excluirAnuncio(anuncio.Id).then(() => {
                    this.toastText = 'Anuncio removido'
                    this.snackbar = true
                    this.$emit('listar-unidades');
                }).catch(error => {

                    if(error.response && error.response.status === 401){
                        Cookie.remove(Strings.cookie_token)
                        Cookie.remove(Strings.cookie_username)
                        this.$router.push('/pages/login')
                    }

                    console.log(error)
                    this.toastText = error.response
                    this.snackbar = true
                    this.$loading(false)
                })
            } catch (error) {
                console.log(error)
                this.toastText = error.response
                this.snackbar = true
                this.$loading(false)
            }
        }
    },
    setup() {

        const toastText = ''
        const snackbar = false
        const momentF = Object
      
        return {
            icons:{
                
            },
            momentF,
            toastText,
            snackbar,
        }

    },
}
</script>

<style scoped>

</style>