<template>
    <v-card>
      <v-card-title>
        <v-icon>
            {{ icons.mdiOfficeBuilding }}
          </v-icon>
            <span class="me-3">&nbsp; Meus Imóveis</span>
            <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <h5 style="color: red !important;">Lembre-se que ao anunciar sua unidade, ela ficará disponível 
          para todos os clientes da incorporadora.</h5>
      </v-card-text>
      <v-data-table
      v-if="this.showTable"
        :headers="headers"
        :items="this.empreendimentos"
        item-key="obra"
        class="table-rounded"
        hide-default-footer
        disable-sort
        fixed-header
        no-data-text="Nenhum imóvel encontrado"
        no-results-text="Nenhum imóvel encontrado"
      >
        <!-- name -->
        <template #[`item.id`]="{item}">
          <v-icon>
                {{ icons.mdiOfficeBuildingMarkerOutline }}
            </v-icon>
        </template>
        <!-- Valor -->
        <template #[`item.valor`]="{item}">
          <v-chip
            small
            class="font-weight-medium"
          >
            {{ item.valor }}
          </v-chip>
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="statusColor[status[item.status]]"
            class="font-weight-medium"
          >
            {{ status[item.status] }}
          </v-chip>
        </template>
        <!-- Ação -->
        <template #[`item.acoes`]="{item}">
          <v-btn
              small
              color="success"
              class="btn-bol text-center"
              @click="abrirAnunciarImovel(item)"
            > <small>Anunciar</small> <small style="visibility: hidden; position: absolute;">{{ item.acoes }}</small>
            </v-btn>
            <v-dialog
                persistent
                :retain-focus="false"
                max-width="500px"
                v-model="dialogAnunciar"
            >
                <v-card>
                <v-card-title>
                  <h4>Insira os dados abaixo para anunciar seu ímovel</h4>
                </v-card-title>
                <v-card-text>
                      <v-form>
                        <v-container>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                                label="Telefone para contato"
                                placeholder="(11) 00000-0000"
                                v-model="telefone"
                                required
                            />
                            </v-col>
                            <v-col cols="6" md="6">
                            <v-text-field
                                label="Valor"
                                placeholder="250000.00"
                                type="number"
                                v-model="valor"
                                required
                            />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <v-textarea
                                label="Descrição"
                                v-model="descricao"
                                :counter="255"
                                required
                                placeholder="Informe uma descrição"
                            />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                              <template>
                                <v-file-input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  label="Fotos do ímovel"
                                  prepend-icon="mdi-camera"
                                  @change="handleFileUpload"
                                ></v-file-input>
                              </template>
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-form>
                    </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="red darken-1"
                    text
                    outlined
                    @click="dialogAnunciar = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                    color="light-green accent-4"
                    text
                    outlined
                    @click="anunciar"
                    >
                        Concluir
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
      </v-data-table>
      <v-snackbar
                v-model="snackbar"
                :timeout="-1"
                >
                {{ toastText }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="primary"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Fechar
                    </v-btn>
                </template>
                </v-snackbar>
    </v-card>
</template>

<script>

import { mdiOfficeBuilding, mdiOfficeBuildingMarkerOutline } from '@mdi/js'
import Cookie from 'js-cookie'
import Strings from '@/common/strings'
import Usuario from '@/store/Usuario'

export default {
    mounted: function(){
        this.toastText = ''
        this.snackbar = false
        this.empreendimentos = JSON.parse(Cookie.get('payloadEmpreendimentos'))
        
        this.showTable = this.empreendimentos.length > 0
    },
    data(){
      return{
        empreendimentos: [],
        dialogAnunciar: false,
        imovelSelecionado: null,
        valor: 0,
        descricao: "",
        telefone: "",
        fotos: null,
      }
    },
    methods: {
      abrirAnunciarImovel(imovelSelecionado) {
        this.imovelSelecionado = imovelSelecionado
        this.dialogAnunciar = true
      },
      handleFileUpload(event) {
        this.fotos = event;
      },
      anunciar(){
        let anunciante = Cookie.get(Strings.cookie_username)

        let formData = new FormData()
        formData.append('ImobiliariaId', Strings.imobiliariaId)
        formData.append('NomeAnunciante', anunciante)
        formData.append('NomeEmpreendimento', this.imovelSelecionado.desc_obra)
        formData.append('Identificador', this.imovelSelecionado.identificador)
        formData.append('Descricao', this.descricao)
        formData.append('Telefone', this.telefone)
        formData.append('Valor', this.valor)
        
        const files = this.fotos;
        
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formData.append(`Fotos`, file);
        }

        this.$loading(true)
        this.dialogAnunciar = false

        Usuario.anunciarUnidade(formData).then(() => {
          this.$loading(false)
          this.$emit('listar-unidades');
          this.$emit('fechar-dialog-unidades');

        }).catch(error => {

          if(error.response && error.response.status === 401){
            Cookie.remove(Strings.cookie_token)
            Cookie.remove(Strings.cookie_username)
            this.$router.push('/pages/login')
          }

          console.log(error)
          this.toastText = error.response.data
          this.snackbar = true
          this.$loading(false)
        })
      }
    },
    setup() {
        const toastText = ''
        const snackbar = false
        const showTable = false
        return {
            headers: [
                { text: '#', value: 'id' },
                { text: 'Obra', value: 'obra' },
                { text: 'Empreendimento', value: 'desc_obra' },
                { text: 'Identificador', value: 'identificador'},
                { text: 'Ações', value: 'acoes' },
            ],
            showTable,
            icons:{
                mdiOfficeBuilding,
                mdiOfficeBuildingMarkerOutline,
            },
            toastText,
            snackbar,
        }
    }
}
</script>