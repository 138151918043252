<template>
    <v-row no-gutters>
        <v-col class="col-12">
            <h3 v-if="this.anuncios.length == 0">Nenhum anuncio encontado.</h3>
        </v-col>
        <v-col class="col-6">
            <h2 class="" v-if="this.anuncios.length > 0">
                <span class="ml-1">Unidades à venda</span>
            </h2>
        </v-col>
        <v-col class="col-6">
            <v-row
                style="display: flex; justify-content: end; align-items: center; margin-right: 0.5em;">
                <v-btn
                    text
                    color="primary"
                    dark
                    >
                    <v-row justify="center">
                    <v-dialog
                        :retain-focus="false"
                        max-width="600px"
                        v-model="dialogMinhasUnidades"
                    >
                    
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Meus anuncios
                        </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <minhas-unidades :anuncios="this.meusAnuncios" @listar-unidades="listarUnidades"></minhas-unidades>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                    <v-btn
                                        color="red darken-1"
                                        text
                                        outlined
                                        @click="dialogMinhasUnidades = false"
                                    >
                                    Fechar
                                </v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                    </v-row>
                </v-btn>
                <v-btn
                    small
                    color="primary"
                    @click="dialogUnidadesAVenda = true"
                >
                    <v-row justify="center">
                        <v-dialog
                            :retain-focus="false"
                            max-width="600px"
                            v-model="dialogUnidadesAVenda"
                        >
                        
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Anunciar
                            </v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-container>
                                        <anunciar-unidade 
                                            @fechar-dialog-unidades="fecharDialogMinhasUnidades" 
                                            @listar-unidades="listarUnidades"></anunciar-unidade>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            color="red darken-1"
                                            text
                                            outlined
                                            @click="dialogUnidadesAVenda = false"
                                        >
                                        Fechar
                                    </v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-btn>
            </v-row>
        </v-col>
        <div class="col-6" style="padding: 1em;"
            v-for="(anuncio, index) in this.anuncios" :key="index">
            <v-card>
                <v-card-text>
                    <v-col class="col-md-12"
                        v-if="anuncio.Fotos && anuncio.Fotos.length > 0">
                        <v-carousel
                            style="height: 300px !important;" cycle hide-delimiter-background >
                            <div
                            v-for="(arquivo, index) in anuncio.Fotos" :key="index">
                                <v-carousel-item
                                        style="max-width: 600px;"
                                        :src="arquivo.Url">
                                </v-carousel-item>
                            </div>
                        </v-carousel>
                    </v-col>
                    <v-row>
                        <v-col cols="12">
                            <h2>{{ anuncio.NomeEmpreendimento }}</h2>
                            <h4>Identificador: <span style="font-weight: 400">{{ anuncio.Unidade }}</span></h4>
                            <h4>Descrição: <span style="font-weight: 400">{{ anuncio.Descricao }}</span></h4>
                            <h4>
                                {{ 
                                    anuncio.Valor.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                }}
                            </h4>
                            <h4>Anunciante: <span style="font-weight: 400">{{ anuncio.Anunciante }}</span></h4>
                            <h4>Telefone: <span style="font-weight: 400">{{ anuncio.Telefone }}</span></h4>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-row>
  </template>

<script>
import { mdiOfficeBuildingMarkerOutline, mdiCheckDecagramOutline, 
    mdiClockOutline, mdiFilePdfBox, mdiStepForward, mdiPercentOutline  } from '@mdi/js'
import Cookie from 'js-cookie'
import UsuarioStore from '@/store/Usuario'
import Strings from '@/common/strings'

import AnunciarUnidade from './AnunciarUnidade.vue'
import MinhasUnidades from './MinhasUnidades.vue'

export default {
    components: {
        MinhasUnidades,
        AnunciarUnidade
    },
    mounted: function(){
        this.listarUnidades()
    },
    data(){
      return{
        dialogMinhasUnidades: false,
        dialogUnidadesAVenda: false,
        anuncios: [],
        meusAnuncios: [],
        dialogs: Array
      }
    },
    methods: {
        listarUnidades(){
            this.$loading(true)
            const listarTodosAnunciosPromise = UsuarioStore.listarTodosAnuncios(Strings.imobiliariaId);
            const listarPorCodPessoaPromise = UsuarioStore.listarPorCodPessoa(Strings.imobiliariaId);

            Promise.all([listarTodosAnunciosPromise, listarPorCodPessoaPromise])
            .then(([resultTodosAnuncios, resultPorCodPessoa]) => {

                this.anuncios = resultTodosAnuncios.data;

                this.meusAnuncios = resultPorCodPessoa.data;
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                Cookie.remove(Strings.cookie_token);
                Cookie.remove(Strings.cookie_username);
                this.$router.push('/pages/login');
                }
                console.error(error);
            })
            .finally(() => {
                this.$loading(false);
            });
        },
        fecharDialogMinhasUnidades(){
            this.dialogUnidadesAVenda = false
        }
    },
    setup() {

        const toastText = ''
        const snackbar = false
        const momentF = Object
      
      return {
        icons:{
            mdiOfficeBuildingMarkerOutline,
            mdiCheckDecagramOutline,
            mdiClockOutline,
            mdiFilePdfBox,
            mdiStepForward,
            mdiPercentOutline 
        },
        momentF,
        toastText,
        snackbar,
      }

    },
}
</script>

<style scoped>

</style>